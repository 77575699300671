
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: "Poppins", sans-serif !important;
}


.App {
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Target only the customLink class */
.customLink {
  text-decoration: none;
  color: inherit;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button {
  text-transform: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.header-section {
  margin-bottom: 3em;
}
.piano-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer{
  text-align: center;
  font-size:12px;
  margin-bottom: -32px;
}
.end-quiz-button {
  width: 100% !important;
  margin-bottom: 28px !important;
}
.results-timestamp{
  font-size: .7em !important;
  color:#666 !important;
  margin-top: 0px !important;
}

.piano-container, .quiz-controls, .MuiAccordion-root, .MuiButton-root {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.uniform-width {
  width: 100%;
  box-sizing: border-box;
}


.quiz-controls {
  margin-top: 3em;
  width:100%;

}
.confetti-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  text-align: center;
}
.level-selector {
  margin-top: 2em;
  margin-bottom:2em;
}

.piano-container {
  height:220px;
}


.feedback-wrapper {
  font-size: 16px;
  margin: auto;
  margin-bottom: 1em;
  width: 50%;
}
.feedback-grid {
width:100%;
margin-bottom: 10px !important;
}
.feedbackMessage {
  text-align: center; /* Center align text */

}

.blank {
  background-color: rgba(239, 121, 24, 0.3);
  border: 2px dotted #EF7918;
  border-radius: 8px;
  padding:6px;
  margin: 3px;
}

.correct {
  background-color: rgba(237, 247, 237, 0.297);
    padding:6px;
    border: 2px dotted #418944;
  border-radius: 8px;
  margin: 3px;
}

.incorrect {
  background-color:  rgb(255 0 0 / 30%);;
  padding:6px;
  border: 2px dotted #D74242;
  border-radius: 8px;
  margin: 3px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hide labels */
.hide-labels .ReactPiano__NoteLabel--natural {
  color: transparent !important;
}

.hide-labels .ReactPiano__NoteLabel--accidental {
  color: transparent !important;
}
.hide-labels .ReactPiano__Key--natural .ReactPiano__NoteLabel--active  {
  color: transparent !important;
}

.hide-labels .ReactPiano__Key--accidental .ReactPiano__NoteLabel--active {
  color: transparent !important;
}


.ReactPiano__NoteLabel {
  font-size: 18px !important;
  text-align: center;
  text-transform: capitalize;
  -webkit-user-select: none;
  user-select: none;
}





/* Base styles for natural keys */
.ReactPiano__Key--natural {
  background:#fff !important;
  background:-webkit-linear-gradient(-20deg,#333,#000,#333);
  background:-moz-linear-gradient(-20deg,#333,#000,#333);
  background:-ms-linear-gradient(-20deg,#333,#000,#333);
  background:-o-linear-gradient(-20deg,#333,#000,#333);
  background:linear-gradient(-20deg,#333,#000,#333);
  z-index:10;
  border-width:1px 2px 7px;
  border-style:solid;
  border-color:#666 #222 #111 #555;
  -webkit-box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  -moz-box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  -webkit-border-radius:0 0 2px 2px;
  -moz-border-radius:0 0 2px 2px;
  border-radius:0 0 2px 2px;
}




/* Base styles for accidental keys */
.ReactPiano__Key--accidental {
  background: #2b2b2b !important;
  background:-webkit-linear-gradient(-20deg,#333,#000,#333);
  background:-moz-linear-gradient(-20deg,#333,#000,#333);
  background:-ms-linear-gradient(-20deg,#333,#000,#333);
  background:-o-linear-gradient(-20deg,#333,#000,#333);
  background:linear-gradient(-20deg,#333,#000,#333);
  z-index:10;
  border-width:1px 2px 7px !important;
  border-style:solid !important;
  border-color:#666 #222 #111 #555 !important;
  -webkit-box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  -moz-box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  box-shadow:inset 0px -1px 2px rgba(255,255,255,0.4),0 2px 3px rgba(0,0,0,0.4);
  -webkit-border-radius:0 0 2px 2px;
  -moz-border-radius:0 0 2px 2px;
  border-radius:0 0 2px 2px;
  height: 55% !important;
}

/* Active state for natural keys */
.ReactPiano__Key--active.ReactPiano__Key--natural {
  -webkit-box-shadow:inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  -moz-box-shadow:inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  box-shadow: inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  border: 1px solid rgba(0,0,0,0.4) !important;
  height:98%!important;
  background: rgba(141, 140, 140, 0.507)  !important;
}
.ReactPiano__Key--natural .ReactPiano__NoteLabel--active {
  color: #111 !important;
}



/* Active state for accidental keys */
.ReactPiano__Key--active.ReactPiano__Key--accidental {
  -webkit-box-shadow:inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  -moz-box-shadow:inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  box-shadow: inset 3px 2px 5px rgba(0,0,0,0.2) !important;
  border-width:1px 2px 7px !important;
  border-style:solid !important;
  border-color:#666 #222 #111 #555 !important;
  background:#222 !important;
}

.ReactPiano__Key--accidental .ReactPiano__NoteLabel--active {
  color: #888 !important;
}