/* NoteStreak.css */
.progress-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.marker {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  font-size: 16px; /* Adjust size as needed */
  animation: none !important; /* Stops any inherited animations, add !important if necessary */
  box-shadow: none !important; /* Removes any shadows */
  text-shadow: none !important; /* Clears text shadows */
  color: inherit; /* Ensures the emoji color isn't altered */
}
.marker.current {
  left: 0;
}

.marker.next {
  right: 0;
}

.note-streak {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Ensures all animations are contained */
  border-radius: 8px; /* Rounded corners */
}

/* Heating Up - simple glow */
.heatingup {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0%, 100% {
    box-shadow: inset 0 0 10px rgba(255, 165, 0, 0.75);
    color: rgba(255, 165, 0, 0.75);
  }
  50% {
    box-shadow: inset 0 0 20px rgba(255, 165, 0, 1);
    color: rgba(255, 165, 0, 1);
  }
}

/* On Fire - faster and brighter flickering */
.onfire {
  animation: flicker 1s infinite;
}

@keyframes flicker {
  0%, 100% {
    box-shadow: inset 0 0 12px red, inset 0 0 24px orange;
    color: red;
  }
  50% {
    box-shadow: inset 0 0 18px red, inset 0 0 28px orange;
    color: orange;
  }
}

/* Blazing - more intense with quicker flickers */
.blazing {
  animation: intenseFlame 0.75s infinite;
}

@keyframes intenseFlame {
  0%, 100% {
    box-shadow: inset 0 0 24px red, inset 0 0 34px orange, inset 0 0 44px yellow;
    color: red;
  }
  50% {
    box-shadow: inset 0 0 29px red, inset 0 0 39px orange, inset 0 0 49px yellow;
    color: yellow;
  }
}

/* Inferno - full-blown and fastest fire */
.inferno {
  animation: fullBlaze 0.5s infinite;
}

@keyframes fullBlaze {
  0%, 100% {
    box-shadow: inset 0 0 34px red, inset 0 0 44px orange, inset 0 0 54px yellow, inset 0 0 64px white;
    color: red;
  }
  50% {
    box-shadow: inset 0 0 39px red, inset 0 0 49px orange, inset 0 0 59px yellow, inset 0 0 69px white;
    color: white;
  }
}

/* Unstoppable - unique and extremely vibrant visual effect */
.unstoppable {
  animation: ultimateGlow 0.3s infinite;
}

@keyframes ultimateGlow {
  0%, 100% {
    box-shadow: inset 0 0 44px purple, inset 0 0 54px blue, inset 0 0 64px cyan;
    color: purple;
  }
  50% {
    box-shadow: inset 0 0 49px purple, inset 0 0 59px blue, inset 0 0 69px cyan;
    color: blue;
  }
}
